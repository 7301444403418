<template>
  <div class="grid sm:grid-cols-1 md:grid-cols-2">
    <asom-form-field label="Station Name">
      {{ get(requestData, "stationName") }}
    </asom-form-field>
    <asom-form-field label="Inventory Group">
      {{ get(requestData, "inventoryGroupName") }}
    </asom-form-field>
    <asom-form-field label="Inventory Type Name">
      {{ get(requestData, "inventoryTypeName") }}
    </asom-form-field>
    <asom-form-field label="Description">
      {{ get(requestData, "description", "NIL") }}
    </asom-form-field>
    <asom-form-field label="Threshold Quantity">
      {{ get(requestData, "thresholdQuantity", 0) }}
    </asom-form-field>
    <asom-form-field label="Required Primary Location">
      {{ get(requestData, "requiredPrimaryLocation") ? "Yes" : "No" }}
    </asom-form-field>
    <asom-form-field label="Required Secondary Location">
      {{ get(requestData, "requiredSecondaryLocation") ? "Yes" : "No" }}
    </asom-form-field>
    <asom-form-field label="Remarks">
      {{ get(requestData, "remarks", "NIL") }}
    </asom-form-field>
  </div>
</template>

<script>
import get from "lodash.get";

export default {
  props: ["requestData"],
  methods: {
    get,
  },
};
</script>