<template>
  <div class="space-y-4">
    <asom-form-field label="Moving in">
      <span v-if="!movingInList.length === 0">No cash bag moving in</span>
      <table
        v-else
        class="mt-2 w-full divide-y divide-gray-200 border border-gray-200"
      >
        <caption class="hidden"></caption>
        <thead class="bg-gray-50">
          <tr>
            <th
              scope="col"
              class="w-1/3 p-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
            >
              Cash Bag No
            </th>
            <th
              scope="col"
              class="px-6 p-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
            >
              Remarks
            </th>
          </tr>
        </thead>
        <tbody class="w-full divide-y divide-gray-200 border border-gray-200">
          <tr
            class="divide-x divide-gray-200"
            v-for="(cashBag, i) in movingInList"
            :key="i"
          >
            <td class=" p-3 text-sm font-medium text-gray-900 text-left">
              {{ cashBag.bagNo }}
            </td>
            <td class=" p-3 text-sm font-medium text-gray-900 text-left">
              {{ cashBag.remarks }}
            </td>
          </tr>
        </tbody>
      </table>
    </asom-form-field>
    <asom-form-field label="Moving out">
      <span v-if="!movingOutList.length === 0">No cash bag moving out</span>
      <table
        v-else
        class="mt-2 w-full divide-y divide-gray-200 border border-gray-200"
      >
        <caption class="hidden"></caption>
        <thead class="bg-gray-50">
          <tr>
            <th
              scope="col"
              class="w-1/3  p-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
            >
              Cash Bag No
            </th>
            <th
              scope="col"
              class="px-6  p-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
            >
              Remarks
            </th>
          </tr>
        </thead>
        <tbody class="w-full divide-y divide-gray-200 border border-gray-200">
          <tr
            class="divide-x divide-gray-200"
            v-for="(cashBag, i) in movingOutList"
            :key="i"
          >
            <td class=" p-3 text-sm font-medium text-gray-900 text-left">
              {{ cashBag.bagNo }}
            </td>
            <td class=" p-3 text-sm font-medium text-gray-900 text-left">
              {{ cashBag.remarks }}
            </td>
          </tr>
        </tbody>
      </table>
    </asom-form-field>
    <asom-form-field label="Witness" v-if="witnessName">
      {{witnessName}}
    </asom-form-field>
  </div>
</template>

<script>
import get from "lodash.get";

export default {
  props: ["requestData"],
  computed: {
    witnessName() {
      return get(this.requestData, 'witnessName')
    },
    cashBagList() {
      return get(this.requestData, "cashBagList", []);
    },
    movingInList() {
      return this.cashBagList.filter(({ movingIn }) => movingIn);
    },
    movingOutList() {
      return this.cashBagList.filter(({ movingIn }) => !movingIn);
    },
    cashBagTableColumns() {
      return ["bagNo", "remarks"];
    },
  },
  methods: {
    get,
  },
};
</script>
