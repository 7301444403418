<template>
  <div class="grid md:grid-cols-2">
    <asom-form-field label="Transaction No">
      {{ get(requestData, "transactionNo") }}
    </asom-form-field>
    <asom-form-field label="Description">
      {{ get(requestData, "description") }}
    </asom-form-field>
    <asom-form-field label="Involved Parties">
      {{ get(requestData, "involvedParties") }}
    </asom-form-field>
    <asom-form-field label="Action Taken">
      {{ get(requestData, "actionTaken") }}
    </asom-form-field>
    <asom-form-field
      v-if="get(requestData, 'otherReason')"
      label="Other Reason"
    >
      {{ get(requestData, "otherReason") }}
    </asom-form-field>
  </div>
</template>

<script>
import get from "lodash.get";

export default {
  props: ["requestData"],
  methods: {
    get,
  },
};
</script>
