<template>
  <div class="grid sm:grid-cols-1 md:grid-cols-2">
    <asom-form-field label="From">
      {{requestData.fromAFC ? 'AFC' : gtmName }}
    </asom-form-field>
    <asom-form-field label="To">
      {{requestData.fromAFC ? gtmName : 'AFC' }}
    </asom-form-field>
    <asom-form-field label="Cash Box Serial Number">
      {{get(requestData, 'cashBoxSerialNo', 'NIL')}}
    </asom-form-field>
    <div></div>
    <asom-form-field label="Remarks">
      {{get(requestData, 'remarks', 'NIL')}}
    </asom-form-field>
  </div>
</template>
<script>
import get from "lodash.get";
import { mapGetters } from 'vuex';

export default {
  props: [
    'requestData',
  ],
  computed: {
    ...mapGetters({
      gtmOptions: "cashManagement/stationCashAccounts/gtmOptions",
    }),
    gtmName() {
      const gtmId = get(this.requestData, 'GTMId');
      const gtm = this.gtmOptions.find(o => o.value === gtmId);
      return get(gtm, 'label', 'GTM');
    }
  },
  methods: {
    get,
  }
}
</script>