<template>
  <div class="space-y-4">
    <asom-alert v-if="!hasData" title="No Pending Request">
      <p class="text-sm">There is no request pending to sync with server</p>
    </asom-alert>
    <asom-card v-for="item in dataList" :key="item.id" title=" ">
      <template v-slot:header>
        <div class="flex justify-between">
          <div class="flex space-x-1">
            <h3 class="text-lg leading-6 font-medium text-gray-900">
              {{ title(item) }}
            </h3>
            <asom-badge :variant="itemBadgeVariant(item)">
              {{ itemBadgeText(item) }}
            </asom-badge>
          </div>
          <span>{{ humanizeDate(item.createdDate) }}</span>
        </div>
      </template>
      <div class="space-y-2">
        <asom-alert
          variant="error"
          v-if="item.error"
          :error-message="item.error"
        />
        <move-cash-bag-details
          v-if="isMoveCashBag(item)"
          :requestData="item.data"
        />
        <move-cash-box-details
          v-else-if="isMoveCashBox(item)"
          :requestData="item.data"
        />
        <use-coin-tube-details
          v-else-if="isUseCoinTube(item)"
          :requestData="item.data"
        />
        <station-transfer-details
          v-else-if="isStationTransfer(item)"
          :requestData="item.data"
        />
        <use-inventory-details
          v-else-if="isUseInventory(item)"
          :requestData="item.data"
        />
        <update-inventory-details
          v-else-if="isUpdateInventory(item)"
          :requestData="item.data"
        />
        <verify-first-aid-box
          v-else-if="isVerifyFirstAidBox(item)"
          :requestData="item.data"
        />
        <move-in-inventories
          v-else-if="isMoveInInventories(item)"
          :requestData="item.data"
        />
        <open-smart-card-box
          v-else-if="isOpenSmartCardBox(item)"
          :requestData="item.data"
        />
        <create-inventory-type
          v-else-if="isCreateInventoryType(item)"
          :requestData="item.data"
        />
        <create-p-s-c-discrepancy
          v-else-if="isCreateDiscrepancy(item)"
          :requestData="item.data"
        />
        <span v-if="isSCCF(item)">
          As this is a long form, please review your request, and possibly add
          more details by clicking at button "Review" below
        </span>
        <div class="flex justify-end gap-2">
          <asom-button
            v-if="isReviewable(item)"
            outline
            size="sm"
            variant="secondary"
            @click="navigateToPage(item)"
            text="Review"
          />
          <asom-button
            text="Remove request"
            variant="error"
            outline
            size="sm"
            @click="confirmRemoval(item.id)"
          />
        </div>
      </div>
    </asom-card>
    <div class="flex justify-end gap-2" v-if="hasData">
      <asom-button
        outline
        @click="uploadData"
        :loading="isUploading"
        icon="software-upload"
        text="Upload to server"
      />
    </div>
    <asom-modal v-model="showModal" title="Confirmation" :dismissible="false">
      <div class="pt-4">
        <p>This will remove the request from syncing list.</p>
        <p>Do you want to continue?</p>
        <div class="flex flex-row-reverse pt-4 gap-4">
          <asom-button
            variant="error"
            @click="removeSelectedRequest"
            text="Confirm"
          />
          <asom-button
            @click="toggleModal(false)"
            text="Cancel"
            variant="secondary"
          />
        </div>
      </div>
    </asom-modal>
  </div>
</template>

<script>
import get from "lodash.get";
import { mapGetters } from "vuex";
import { humanizeDate } from "@/helpers/dateTimeHelpers";
import {
  CREATE_STATION_CASH_COLLECTION_FORM,
  CREATE_STATION_TRANSFER,
  MOVE_CASH_BAGS_AFC,
  MOVE_CASH_BOX,
  OPEN_SMART_CARD_BOX,
  UPDATE_INVENTORY,
  USE_COIN_TUBE,
  USE_ITEMS,
  VERIFY_FIRST_AID_BOX,
  CREATE_DISCREPANCY_REPORT,
  MOVE_IN_ITEMS,
  CREATE_INVENTORY_TYPE,
} from "../../constants/apis";
import { InventoryGroups } from "../../constants/APIEnums/inventoryEnums";
import MoveCashBagDetails from "./details/MoveCashBagDetails.vue";
import MoveCashBoxDetails from "./details/MoveCashBoxDetails.vue";
import UseCoinTubeDetails from "./details/UseCoinTubeDetails.vue";
import UseInventoryDetails from "./details/UseInventoryDetails.vue";
import UpdateInventoryDetails from "./details/UpdateInventoryDetails.vue";
import StationTransferDetails from "./details/StationTransferDetails.vue";
import VerifyFirstAidBox from "./details/VerifyFirstAidBox.vue";
import OpenSmartCardBox from "./details/OpenSmartCardBox.vue";
import CreatePSCDiscrepancy from "./details/CreatePSCDiscrepancy.vue";
import MoveInInventories from "./details/MoveInInventories.vue";
import CreateInventoryType from "./details/CreateInventoryType.vue";

export default {
  components: {
    MoveCashBagDetails,
    MoveCashBoxDetails,
    UseCoinTubeDetails,
    UseInventoryDetails,
    UpdateInventoryDetails,
    StationTransferDetails,
    VerifyFirstAidBox,
    OpenSmartCardBox,
    CreatePSCDiscrepancy,
    MoveInInventories,
    CreateInventoryType,
  },
  data() {
    return {
      isUploading: false,
      selectedId: null,
      showModal: false,
    };
  },
  beforeUnmount() {
    this.clearSyncedRequests();
  },
  computed: {
    ...mapGetters({
      hasData: "offlineData/hasData",
      idList: "offlineData/idList",
      dataList: "offlineData/dataList",
    }),
  },
  methods: {
    humanizeDate,
    title({ url, data }) {
      const inventoryGroup = get(data, "inventoryGroup");
      switch (url) {
        case MOVE_CASH_BAGS_AFC:
          return "Move Cash Bags (AFC)";
        case MOVE_CASH_BOX:
          return "Move Cash Box (AFC)";
        case USE_COIN_TUBE:
          return "Coin Tube Usage (AFC)";
        case CREATE_STATION_CASH_COLLECTION_FORM:
          return "Station Cash Collection Form";
        case CREATE_STATION_TRANSFER:
          return "Station Transfer";
        case UPDATE_INVENTORY:
          switch (inventoryGroup) {
            case InventoryGroups.GENERAL.VALUE:
              return "Update General Inventory";
            case InventoryGroups.SMART_CARD.VALUE:
              return "Update Smart Card Inventory";
            case InventoryGroups.SERIALISED_TAG.VALUE:
              return "Update Digital Record Inventory";
            case InventoryGroups.FIRST_AID.VALUE:
              return "Update First Aid Inventory";
            default:
              return "UNKNOWN REQUESTS";
          }
        case USE_ITEMS:
          switch (inventoryGroup) {
            case InventoryGroups.GENERAL.VALUE:
              return "Use General Inventory";
            case InventoryGroups.SMART_CARD.VALUE:
              return "Use Smart Card Inventory";
            case InventoryGroups.SERIALISED_TAG.VALUE:
              return "Use Digital Record Inventory";
            case InventoryGroups.FIRST_AID.VALUE:
              return "Use First Aid Inventory";
            default:
              return "UNKNOWN REQUESTS";
          }
        case MOVE_IN_ITEMS:
          return 'Move In Inventories';
        case VERIFY_FIRST_AID_BOX:
          return "Verify First Aid Box";
        case OPEN_SMART_CARD_BOX:
          return "Open Smart Card Box";
        case CREATE_DISCREPANCY_REPORT:
          return "Create Discrepancy Report";
        case CREATE_INVENTORY_TYPE:
          return "New Item Type";
        default:
          return "UNKNOWN REQUESTS";
      }
    },
    itemBadgeVariant({ isSync, error }) {
      if (isSync) {
        return "success";
      } else if (error) {
        return "error";
      } else {
        return "secondary";
      }
    },
    itemBadgeText({ isSync, error }) {
      if (isSync) {
        return "Synced";
      } else if (error) {
        return "Failed to sync";
      } else {
        return "Pending syncing";
      }
    },
    toggleModal(value) {
      this.showModal = value;
    },
    confirmRemoval(id) {
      this.selectedId = id;
      this.toggleModal(true);
    },
    removeSelectedRequest() {
      this.$store.commit("offlineData/removeRequests", [this.selectedId]);
      this.selectedId = null;
      this.toggleModal(false);
    },
    async uploadData() {
      this.isUploading = true;
      await this.$store.dispatch("offlineData/sendPendingSyncData");
      this.isUploading = false;
    },
    clearSyncedRequests() {
      this.$store.dispatch("offlineData/removeSyncedRequests");
    },
    // categorize requests
    isMoveCashBag(data) {
      return data.url === MOVE_CASH_BAGS_AFC;
    },
    isMoveCashBox(data) {
      return data.url === MOVE_CASH_BOX;
    },
    isUseCoinTube(data) {
      return data.url === USE_COIN_TUBE;
    },
    isSCCF(data) {
      return data.url === CREATE_STATION_CASH_COLLECTION_FORM;
    },
    isStationTransfer(data) {
      return data.url === CREATE_STATION_TRANSFER;
    },
    isUseInventory(data) {
      return data.url === USE_ITEMS;
    },
    isUpdateInventory(data) {
      return data.url === UPDATE_INVENTORY;
    },
    isVerifyFirstAidBox(data) {
      return data.url === VERIFY_FIRST_AID_BOX;
    },
    isMoveInInventories(data) {
      return data.url === MOVE_IN_ITEMS
    },
    isOpenSmartCardBox(data) {
      return data.url === OPEN_SMART_CARD_BOX;
    },
    isCreateInventoryType(data) {
      return data.url === CREATE_INVENTORY_TYPE;
    },
    isCreateDiscrepancy(data) {
      return data.url === CREATE_DISCREPANCY_REPORT;
    },
    isReviewable(data) {
      return this.isSCCF(data);
    },
    navigateToPage(data) {
      switch (data.url) {
        case CREATE_STATION_CASH_COLLECTION_FORM:
          this.$router.push({
            name: "Create SCCF",
            query: {
              offlineId: data.id,
            },
          });
          return;
        default:
          return;
      }
    },
  },
};
</script>
