<template>
  <div class="grid sm:grid-cols-1 md:grid-cols-2">
    <asom-form-field label="Cash Order">
      <span>{{cashOrderNo}}</span>
    </asom-form-field>
    <asom-form-field label="Requested Station">
      <span>{{station}}</span>
    </asom-form-field>
    <asom-form-field class="col-span-2" label="Cash Bag List">
      <asom-client-table
        :filterable="false"
        :pagination="false"
        :data="get(requestData, 'cashBagList', [])"
        :columns="[
          'bagNo',
          'remarks'
        ]"
      />
    </asom-form-field>
    <asom-form-field class="col-span-2" label="Description">
      <asom-input-textarea
        readonly
        :modelValue="get(requestData, 'description', 'NIL')"
      />
    </asom-form-field>
    <asom-form-field label="Recipient Staff">
      <span>{{get(requestData, 'staffRecipientName', 'NIL')}}</span>
    </asom-form-field>
  </div>
</template>
<script>
import get from "lodash.get";

export default {
  props: [
    'requestData',
  ],
  computed: {
    cashOrder() {
      const cashOrderId = get(this.requestData, 'cashOrderId');
      const cashOrder = this.$store.getters['cashManagement/cashOrders/getById'](cashOrderId);
      return cashOrder;
    },
    cashOrderNo() {
      return get(this.cashOrder, 'referenceNo');
    },
    station() {
      return get(this.cashOrder, 'requestorName');
    }
  },
  methods: {
    get,
  }
}
</script>