<template>
  <div class="grid sm:grid-cols-1 md:grid-cols-2">
    <asom-form-field label="Name">
      {{get(requestData, 'inventoryName')}}
    </asom-form-field>
    <asom-form-field label="Used Amount">
      {{get(requestData, 'amount')}}
    </asom-form-field>
    <asom-form-field label="Remarks">
      {{get(requestData, 'remarks')}}
    </asom-form-field>
  </div>
</template>
<script>
import get from "lodash.get";

export default {
  props: [
    'requestData',
  ],
  computed: {
  },
  methods: {
    get,
  }
}
</script>