<template>
  <div class="grid sm:grid-cols-1 md:grid-cols-2">
    <asom-form-field label="Smart Card Box Name">
      {{ get(requestData.itemDetails, "inventoryTypeName") }}
    </asom-form-field>
    <asom-form-field label="Item Description">
      {{ get(requestData.itemDetails, "inventoryTypeName") }}
    </asom-form-field>
    <asom-form-field label="Primary Location Name">
      {{ get(requestData.itemDetails, "primaryLocationName") }}
    </asom-form-field>
    <asom-form-field label="Quantity">
      {{ get(requestData.itemDetails, "amount") }}
    </asom-form-field>
  </div>
</template>

<script>
import get from "lodash.get";

export default {
  props: ["requestData"],
  methods: {
    get,
  },
};
</script>
