<template>
  <div class="grid sm:grid-cols-1 md:grid-cols-2">
    <asom-form-field label="First Aid Box Name">
      {{ get(requestData, "boxName") }}
    </asom-form-field>
    <asom-form-field label="Remarks">
      {{ get(requestData, "remarks") }}
    </asom-form-field>
  </div>
</template>

<script>
import get from "lodash.get";

export default {
  props: ["requestData"],
  methods: {
    get,
  },
};
</script>
