<template>
  <div class="grid sm:grid-cols-1 md:grid-cols-2 gap-4">
    <asom-form-field label="Order No">
      {{get(requestData, 'inventoryOrderNo', '-')}}
    </asom-form-field>
    <div></div>
    <asom-form-field label="Inventories" class="col-span-2">
      <div class="space-y-4">
        <asom-card v-for="(item, i) in orderItems" :key="i">
          <div class="grid sm:grid-cols-1 md:grid-cols-2 gap-2">
            <asom-form-field label="Inventory Group">
              {{get(item, 'inventoryGroup', '-')}}
            </asom-form-field>
            <asom-form-field label="Inventory Name">
              {{get(item, 'inventoryTypeName', '-')}}
            </asom-form-field>
            <asom-form-field label="Amount">
              {{get(item, 'amount', '-')}}
            </asom-form-field>
            <asom-form-field label="Remarks">
              {{get(item, 'remarks', '-')}}
            </asom-form-field>
            <asom-form-field label="Secondary Location" v-if="secondaryLocation(item)">
              {{secondaryLocation(item)}}
            </asom-form-field>
            <asom-form-field label="Expiry Date" v-if="expiryDate(item)">
              {{expiryDate(item)}}
            </asom-form-field>
          </div>
        </asom-card>
      </div>
    </asom-form-field>
    <asom-form-field label="Remarks">
      {{get(requestData, 'remarks', '-')}}
    </asom-form-field>
  </div>
</template>

<script>
import get from "lodash.get";
import { displayUtcDate } from "@/helpers/dateTimeHelpers";

export default {
  props: ["requestData"],
  methods: {
    get,
    secondaryLocation(item) {
      return get(item, 'secondaryLocationName', null);
    },
    expiryDate(item) {
      const date = get(item, 'expiryDate', null);
      if(date)
        return displayUtcDate(date);
      return null;
    }
  },
  computed: {
    orderItems() {
      return get(this.requestData, 'receivedOrder', []);
    }
  }
};
</script>
